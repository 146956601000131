import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#036203" },
    background: { default: "#51687b" },
  },
  typography: {
    fontFamily: ["Andika", "sans-serif"].join(","),
    h1: {
      fontFamily: ['"Amatic SC"', "sans-serif"].join(","),
    },
    h2: {
      fontFamily: ['"Amatic SC"', "sans-serif"].join(","),
    },
    h3: {
      fontFamily: ['"Amatic SC"', "sans-serif"].join(","),
    },
    h4: {
      fontFamily: ['"Amatic SC"', "sans-serif"].join(","),
    },
    h5: {
      fontFamily: ['"Amatic SC"', "sans-serif"].join(","),
    },
    h6: {
      fontFamily: ['"Amatic SC"', "sans-serif"].join(","),
    },
  },
})

export default theme
